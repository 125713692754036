const CONTROL_FLOW_NAMES = new Set(['if', 'else', 'for', 'while', 'do', 'switch', 'case', 'break', 'continue', 'default', 'return'])
const VAR_DECLARATION_NAMES = new Set(['var', 'let', 'const'])

export class DslFormulaPreprocessor {
    private cursor: StringCursor
    private multiline: boolean = false
    private controlFlow: boolean = false
    constructor(str: string, private liFnNames: Set<string>) {
        this.cursor = new StringCursor(str.trim())
    }

    parse() {
        let result = ''
        let before = -1  // we use "before" to break the while-loop if the cursor doesn't advance
        while (this.cursor.hasNext() && before !== this.cursor.value) {
            before = this.cursor.value
            result += this.parseSymbol(true)
        }

        return this.multiline || this.controlFlow ? result : 
        (result.trim().startsWith('return') ? result : `return (${result})`)
    }

    get errors() {
        return this.cursor.errors
    }

    private parseSymbol(liStrings: boolean) {
        const char = this.cursor.get()
        switch (true) {
            case '"' === char: return liStrings ? this.parseLineItemString('"') : this.parseString('"')
            case "'" === char: return this.parseString("'")
            case "`" === char: return this.parseString("`")
            case '^' === char: this.cursor.forward() ; return '**'
            case char.match(/[a-zA-Z_]/) !== null: return this.parseInstruction()
            case char.match(/[0-9]/) !== null: return this.parseNumber()
            case '(' === char: return this.parseStructure('(', ')', true)
            case '[' === char: return this.parseStructure('[', ']', true)
            case '{' === char: return this.parseStructure('{', '}', true)
            case '/' === char && this.cursor.get(1) === '/': this.skipLineComment(); return ''
            case '/' === char && this.cursor.get(1) === '*': this.skipBlockComment(); return ''
            case ' ' === char: return this.parseWhitespace()
            case '\n' === char: this.multiline = true ; this.cursor.forward() ; return '\n'
            default: this.cursor.forward() ; return char
        }
    }

    private parseWhitespace() {
        let whitespace = ' '
        if (this.cursor.value > 0 && this.cursor.get(-1) === '\n') {
            whitespace = ''
        }
        this.skipWhitespaceAndComments()
        return whitespace
    }

    private parseNumber() {
        let num = this.parseInteger()
        if (this.cursor.hasNext() && this.cursor.get() === '.' && this.isInteger(1)) {
            num += '.'
            this.cursor.forward()
            num += this.parseInteger()
        }
        return num
    }

    private parseInteger() {
        let num = ''
        while (this.cursor.hasNext() && this.isInteger()) {
            num += this.cursor.get()
            this.cursor.forward()
        }
        return num
    }

    private parseInstruction() {
        const ident = this.parseIdent()
        const isControlFlow = CONTROL_FLOW_NAMES.has(ident)
        if (isControlFlow) {
            this.controlFlow = true
        }
        this.skipWhitespaceAndComments()
        if (this.liFnNames.has(ident)) {
            return ident + this.parseFunctionArgumentsWithoutLiStrings()
        } else if (this.cursor.get() === '(' || this.cursor.get() === '[') {
            return ident
        } else if (VAR_DECLARATION_NAMES.has(ident) || isControlFlow) {
            return '\n' + ident + ' '
        } else {
            return ident + ' '
        }
    }

    private parseFunctionArgumentsWithoutLiStrings() {
        return this.parseStructure('(', ')', false)
    }

    private parseIdent() {
        let ident = ''
        while (this.cursor.hasNext() && this.isIdent()) {
            ident += this.cursor.get()
            this.cursor.forward()
        }
        return ident
    }

    private parseString(delim: string) {
        if (this.cursor.get() !== delim) {
            return ''
        }
        let str = ''
        this.cursor.forward()
        while (this.cursor.hasNext() && this.cursor.get() !== delim) {
            str += this.cursor.get()
            this.cursor.forward()
        }
        this.cursor.forward()
        return delim + str + delim
    }

    private parseLineItemString(delim: string) {
        const liName = this.parseString(delim)
        const beforeSkip = this.cursor.value
        this.skipWhitespaceAndComments()
        if (this.cursor.get() !== '[') {
            if (this.cursor.value !== beforeSkip && this.cursor.get(-1) !== '\n') {
                this.cursor.backward()
            }
            return `li(${liName})`
        }
        this.cursor.forward()
        let bracket = ''
        let before = -1
        while (this.cursor.hasNext() && before !== this.cursor.value) {
            before = this.cursor.value
            const char = this.cursor.get()
            if (char === ']') {
                break
            }
            bracket += this.parseSymbol(true)
        }
        this.cursor.forward()
        return `li(${liName}, ${bracket})`
    }

    private parseStructure(left: string, right: string, liStrings: boolean) {
        if (this.cursor.get() !== left) {
            return ''
        }
        this.cursor.forward()
        this.skipWhitespaceAndComments()
        let result = ''
        let before = -1
        while (this.cursor.hasNext() && before !== this.cursor.value) {
            before = this.cursor.value
            if (this.cursor.get() === right) {
                break
            }
            if (this.cursor.get() === '\n') {
                this.skipWhitespaceAndComments()
            } else {
                result += this.parseSymbol(liStrings)
            }
        }
        this.cursor.forward()
        return left + result.trim() + right
    }

    private isInteger(n: number = 0) {
        return this.cursor.get(n).match(/[0-9]/) !== null
    }

    private isIdent(n: number = 0) {
        return this.cursor.get(n).match(/[a-zA-Z_]/) !== null
    }

    private skipWhitespaceAndComments() {
        let before = -1
        while (this.cursor.hasNext() && before !== this.cursor.value) {
            before = this.cursor.value
            const char = this.cursor.get()
            if (char.match(/\s/) !== null) {
                this.cursor.forward()
                continue
            } else if (char === '/') {
                if (this.cursor.get(1) === '/') {
                    this.skipLineComment()
                    continue
                } else if (this.cursor.get(1) === '*') {
                    this.skipBlockComment()
                    continue
                }
            }

            break
        }
    }

    private skipLineComment() {
        if (this.cursor.get() !== '/' || this.cursor.get(1) !== '/') {
            return
        }
        while (this.cursor.hasNext()) {
            const char = this.cursor.get()
            this.cursor.forward()
            if (char === '\n') {
                break
            }
        }
    }

    private skipBlockComment() {
        if (this.cursor.get() !== '/' || this.cursor.get(1) !== '*') {
            return
        }
        this.cursor.forward(2)
        while (this.cursor.hasNext()) {
            if (this.cursor.get() === '*' && this.cursor.get(1) === '/') {
                this.cursor.forward(2)
                this.skipWhitespaceAndComments()
                break
            }
            this.cursor.forward()
        }
    }
}

class StringCursor {
    private cursor = 0
    readonly errors: Error[] = []

    constructor(private str: string) {}

    get value() {
        return this.cursor
    }

    get(n: number = 0): string {
        return this.str[this.cursor + n] || ''
    }

    forward(times: number = 1): void {
        this.assertNonNegativeInteger(times)
        this.cursor += times
    }

    backward(times: number = 1): void {
        this.assertNonNegativeInteger(times)
        this.cursor -= times
        this.assertInRangeInteger(this.cursor)
    }

    hasNext(): boolean {
        return this.cursor < this.str.length
    }

    private assertNonNegativeInteger(n: number) {
        if (n < 0) {
            this.errors.push(new Error(`n must be positive, got ${n}`))
        }
        this.assertInteger(n)
    }

    private assertInRangeInteger(n: number) {
        if (n < 0 || n >= this.str.length) {
            this.errors.push(new Error(`n must be in range, got ${n}`))
        }
        this.assertInteger(n)
    }

    private assertInteger(n: number) {
        if (n !== Math.floor(n)) {
            this.errors.push(new Error(`n must be an integer, got ${n}`))
        }
    }
}