import {formatDistance} from "date-fns";
import {useCompanyId} from "../../core";
import {AmProject, AMProjectAttachment} from "../../ps-types";
import {DeleteButton} from "../../ui/crud/DeleteButton.component";
import {buildEntityDef, EntityCrud} from "../../ui/crud/EntityCrud.component";
import {
  deleteAmProjectAttachment,
  getAmProject,
  getAttachmentDownloadUrl,
} from "./amProject.client";
import {useEffect, useState} from "react";
import {LoadingBlock} from "../../ui/Loading";
import {Button} from "semantic-ui-react";
import { useMessages } from "../../ui/MessagesProvider";


function timeAgo(value: string) {
  return value ? formatDistance(new Date(value), new Date(), {addSuffix: true}) : "";
}

function DeleteBtn({entityName, entity, handleDelete}: {entityName: string, entity: any, handleDelete: (entity: any) => void}) {
  const content =`Are you sure you want to delete the ${entityName} '${entity["title"]}'?`;

  return <DeleteButton
    content={content}
    onDelete={() => handleDelete(entity)}
  />
}


export function ProjectAttachmentsEntityCrud({projectId}: {projectId: string}) {

  let [project, setProject] = useState<AmProject | undefined>();
  const companyId = useCompanyId();
  const {clear, error} = useMessages();

  useEffect(() => {
    clear();
    getAmProject(companyId, projectId).then(setProject).catch((e)=>{
      console.error("Could not fetch project", e)
      error("Something went wrong. Please refresh the page")
    });
  }, [companyId, projectId]);

  if(!project) {
    return <LoadingBlock />
  }

  const downloadAttachment =  async (attachmentData: AMProjectAttachment & { id: string }) => {
    const { attachmentId  } = attachmentData;
    getAttachmentDownloadUrl(companyId, projectId, attachmentId)
      .then(async (res) => {
        window.open(res.url, "_blank")
      });
  } 

  const getAttachmentEntities = () => getAmProject(companyId, projectId).then(p =>
    p.attachments?.map(at => ({...at,
      id: at.attachmentId,
      hasFile: at.fileUri !== null,
      }))
      .sort((a, b) => b.createdAt - a.createdAt ) ?? []
  );

  const deleteAttachmentEntity = (data: any) => deleteAmProjectAttachment(companyId, projectId, data.attachmentId);


  let attachmentDef = buildEntityDef(
    {
      entityName: "Attachments",
      title: "Attachments",
      getEntities: getAttachmentEntities,
      deleteEntity: deleteAttachmentEntity,
      table: {
        deleteButton: (props)=> (<DeleteBtn {...props}/>),
      },
    },
    {
      fileName: {
        title: "File Name"
      },
      createdAt: {
        title: "CREATED",
        create: { type: "hidden" },
        table: { format: timeAgo }
      },
      downloadUrl: {
        title: "Download",
        table: {
          render: (value:any, entity) =>
            entity.hasFile && <Button size="mini" onClick={()=>downloadAttachment(entity)}> Download </Button>
        }
      }
    }
  );
  
  return <EntityCrud entityDef={attachmentDef} />
}