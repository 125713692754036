import { AxiosError, AxiosResponse } from "axios";
import {LineItemsStore, PersistenceQueryDto} from "../../ps-models/lineitems-store";
import psPlatformClient from "../../psPlatformClient";
import { dissoc } from "ramda";

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  response?: any;
}

// Store chat history at file level
let chatHistory: ChatMessage[] = [];

interface AIQueryRequest {
  prompt: string;
  collection: string;
  companyId: string;
  messages: ChatMessage[];
}



interface AIQueryResponse {
  store: any;
  query: any;
  visualization: {
    type: 'table' | 'line' | 'bar' | 'metrics';
    aggregations?: Record<string, string>;
  };
  calculatedLineItems?: any;
}

const AIQueryResponseWrapper = async <Req, Res>(request: Promise<AxiosResponse<Res, Req>>): Promise<Res> => {
  try {
    const result = await request;
    return result.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response && err.response.status >= 400) {
      if (err.response.data && typeof err.response.data === 'object') {
        const errResponseDescription = err.response.data?.description;
        if (errResponseDescription?.reason) {
          throw new Error(errResponseDescription?.reason);
        }
      }
    }
    throw err;
  }
};

export const executeAIQuery = async (prompt: string, collection: string, companyId: string): Promise<{ store: LineItemsStore; visualization: AIQueryResponse['visualization'] }> => {
  // Add user message to history
  chatHistory.push({
    role: 'user',
    content: prompt
  });

  const result = await AIQueryResponseWrapper<AIQueryRequest, AIQueryResponse>(
    psPlatformClient<AIQueryRequest, AIQueryResponse>({
      method: 'post',
      url: `/am/${companyId}/ai-chatbot/query`,
      data: { 
        prompt, 
        collection, 
        companyId,
        messages: chatHistory
      }
    })
  );

  // Add assistant response to history
  chatHistory.push({
    role: 'assistant',
    content: "Here's the data you requested:",
    response: {
      ...(dissoc('store', result))
    }
  });

  return {
    store: LineItemsStore.deserialize(result.store),
    visualization: result.visualization
  };
};

export const clearChatHistory = () => {
  chatHistory = [];
}; 