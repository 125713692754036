import { useEffect, useState } from "react";
import { authStorage } from "../../../auth";
import { createAmProject, getAmProjects } from "../../projects/amProject.client";
import { PS_URLS } from "../../../core";
import { useMessages } from "../../../ui/MessagesProvider";
import { PageLoading } from "../../../ui/Loading";
import { useHistory } from "react-router-dom";
// import { checkENodeConnectionStatus } from "../../icp4/icp4.client";

interface AuthProps {
    render?: () => JSX.Element;
    children?: JSX.Element;
}

export function ICP4AuthWrapper({render, children}: AuthProps): JSX.Element {
  const company = authStorage.getCompany();
  const companyId = company.id;
  const [mandatoryAttachmentsExist, setMandatoryAttachmentsExist] = useState<boolean>(false);
  const [IsProjectLinkedWithEnode, setIsProjectLinkedWithEnode] = useState<boolean>(false);
  const [firstProjectId, setFirstProjectId] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const {clear, error} = useMessages();
  const history = useHistory();

  const companyHasAlreadyBeenOnboarded = authStorage.getMaybeUser()?.company?.accessRequestAccepted;

  useEffect(() => {
      if (render && children) console.error("You shouldn't use render and children at the same time")
      else if (!render && !children) console.error("You should use render or children")
  }, [render, children])


  useEffect(() => {
      if(companyHasAlreadyBeenOnboarded) return;
      async function load() {
        setIsLoading(true);
        let projects = await getAmProjects(companyId);
        setMandatoryAttachmentsExist(projects?.some((p)=>(p.attachments && p.attachments?.length >= 2)))
        setFirstProjectId(projects?.[0]?.id);
        setIsLoading(false);
      }
      load().catch(e => error(e.message));
    }, [companyId, companyHasAlreadyBeenOnboarded]);


    useEffect(() => {
      if(companyHasAlreadyBeenOnboarded) return;
      if(isLoading) return;
      if(firstProjectId) return;
      if(!firstProjectId){
        async function createFirstProject() {
          let project = await createAmProject(companyId);
          setFirstProjectId(project.id);
        }
        setIsLoading(true);
        createFirstProject().catch(e => error(e.message)).finally(()=>setIsLoading(false));
      }
    }, [isLoading, firstProjectId, companyHasAlreadyBeenOnboarded]);


    // useEffect(() => {
    //   if(companyHasAlreadyBeenOnboarded) return;
    //   if(isLoading) return;
    //   if(!firstProjectId) return;
    //   if(!mandatoryAttachmentsExist) return;
    //   if(IsProjectLinkedWithEnode) return;
    //   async function checkEnodeConnection() {
    //     if(!firstProjectId) return;
    //     const { isLinked } = await checkENodeConnectionStatus(companyId, firstProjectId);
    //     setIsProjectLinkedWithEnode(isLinked);
    //   }
    //   setIsLoading(true);
    //   checkEnodeConnection().catch(e => error(e.message)).finally(()=>setIsLoading(false));
    //   }, [IsProjectLinkedWithEnode, companyHasAlreadyBeenOnboarded, isLoading, firstProjectId, mandatoryAttachmentsExist])
    
    useEffect(() => {
      if(isLoading) return;
      if (companyHasAlreadyBeenOnboarded) {
        return;
      } else if (firstProjectId && !mandatoryAttachmentsExist){
        history.push(PS_URLS(companyId).icp4FirstSiteDocumentsUpload(firstProjectId));
        return;
      } else if (firstProjectId && mandatoryAttachmentsExist && !IsProjectLinkedWithEnode){
        history.push(PS_URLS(companyId).icp4FirstSiteVendorOnboarding(firstProjectId));
        return;
      }
      // else if(firstProjectId && mandatoryAttachmentsExist && IsProjectLinkedWithEnode){
      //   history.push(PS_URLS(companyId).icp4InterestRegistered(firstProjectId));
      //   setCurrentStep("access-requested-registered");
      //   return;
      // }
    }, [isLoading, companyHasAlreadyBeenOnboarded, IsProjectLinkedWithEnode, firstProjectId, mandatoryAttachmentsExist]);
    
    if(!companyHasAlreadyBeenOnboarded && isLoading){
      return <PageLoading />
    }

    return render ? render() : <>{children}</>;

}