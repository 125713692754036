import {useEffect, useState} from "react";
import {Button, Header, Segment, Icon, Progress, Step, SemanticICONS} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {PS_URLS, useCompany, useCompanyId} from "../../core";
import {getAmProject, uploadAmProjectAttachment} from "./amProject.client";
import {AmProject} from "../../ps-types";
import {Loading, LoadingBlock} from "../../ui/Loading";
import {usePopup} from "../../ui/popup/Popup";
import {useMessages} from "../../ui/MessagesProvider";
import {ErrorPopup} from "../../ui/popup/ErrorPopup";
const {Grid, Card} = require("semantic-ui-react");


interface UploadState {
  file: File | null;
  progress: number;
  status: 'idle' | 'uploading' | 'processing' | 'completed' | 'error';
  type: 'proposal' | 'bills' | null;
}

interface DragState {
  isDragging: boolean;
}

const ACCEPTED_FILE_TYPES = '.pdf';
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

export function ICP4ProjectUploads({projectId}: {projectId: string}) {
  const companyId = useCompanyId();
  const [project, setProject] = useState<AmProject>();
  const history = useHistory();
  const { clear, error: showError } = useMessages();
  const amProjectId = useCompany().amProjectId;
  const { openPopup, closePopup } = usePopup();

  const [proposalUpload, setProposalUpload] = useState<UploadState>({
    file: null,
    progress: 0,
    status: 'idle',
    type: 'proposal'
  });

  const [billsUpload, setBillsUpload] = useState<UploadState>({
    file: null,
    progress: 0,
    status: 'idle',
    type: 'bills'
  });

  useEffect(() => {
    if (proposalUpload.status === 'completed' && billsUpload.status === 'completed') {
      setTimeout(() => {
        history.push(PS_URLS(companyId).icp4FirstSiteVendorOnboarding(projectId));
      }, 800);
    }
  }, [proposalUpload.status, billsUpload.status, companyId, projectId]);

  useEffect(() => {
    loadProject();
  }, []);

  const loadProject = async () => {
    try {
      const res = await getAmProject(companyId, projectId);
      setProject(res);
    } catch (err) {
      showError('Failed to load project details');
    }
  };

  const validateFile = (file: File): string | null => {
    if (!file.name.toLowerCase().endsWith('.pdf')) {
      return 'Only PDF files are accepted';
    }
    if (file.size > MAX_FILE_SIZE) {
      return 'File size must be less than 20MB';
    }
    return null;
  };

  const handleFileDrop = (file: File, type: 'proposal' | 'bills') => {
    const error = validateFile(file);
    if (error) {
      openPopup(<ErrorPopup e={new Error(error)} header='Invalid File' onClose={closePopup} />);
      return;
    }

    const setState = type === 'proposal' ? setProposalUpload : setBillsUpload;
    setState(prev => ({ ...prev, file, status: 'idle' }));
  };

  const handleUpload = async (type: 'proposal' | 'bills') => {
    const upload = type === 'proposal' ? proposalUpload : billsUpload;
    const setState = type === 'proposal' ? setProposalUpload : setBillsUpload;

    if (!upload.file) return;

    setState(prev => ({ ...prev, status: 'uploading', progress: 0 }));
    
    try {
      const res = await uploadAmProjectAttachment(companyId, projectId, upload.file,
        (progress) => setState(prev => ({ ...prev, progress }))
      );
      setState(prev => ({ ...prev, status: 'completed' }));
    } catch (err) {
      setState(prev => ({ ...prev, status: 'error' }));
      console.error(err);
      openPopup(<ErrorPopup e={err} header='Upload Failed' onClose={closePopup} />);
    }
  };

  const UploadCard = ({ type, state, onFileSelect }: {
    type: 'proposal' | 'bills',
    state: UploadState,
    onFileSelect: (file: File) => void
  }) => {
    const isProposal = type === 'proposal';
    const color = '#6435C9';
    const title = isProposal ? 'Solar Proposal' : 'Energy Bills';
    const description = isProposal 
      ? 'Upload your solar proposal document, this is generally provided by the installer'
      : 'Upload your energy bills, this is generally provided by your utility company';
    const icon: SemanticICONS = isProposal ? 'file pdf' : 'file alternate';
    
    const [dragState, setDragState] = useState<DragState>({
      isDragging: false,
    });

    const handleDragEnter = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      
      if (e.dataTransfer.items && e.dataTransfer.items[0]) {
        setDragState({ isDragging: true });
      }
    };

    const handleDragLeave = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragState({ isDragging: false });
    };

    const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragState({ isDragging: false });

      const droppedFiles = Array.from(e.dataTransfer.files);
      if (droppedFiles.length > 0) {
        handleFileDrop(droppedFiles[0], type);
      }
    };

    const dropZoneStyle = {
      border: `2px dashed ${dragState.isDragging ? '#48BB78' : '#E2E8F0'}`,
      borderRadius: '8px',
      padding: '24px',
      textAlign: 'center' as const,
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      backgroundColor: dragState.isDragging ? 'rgba(72, 187, 120, 0.1)' : '#FFFFFF',
    };

    const [isHovered, setIsHovered] = useState(false);
    
    return (
      <div style={{
        position: 'relative',
        background: '#FFFFFF',
        borderRadius: '12px',
        padding: '32px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        border: '1px solid #E2E8F0',
        transition: 'all 0.2s ease',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {/* <div style={{ 
          position: 'absolute',
          top: '20px',
          right: '20px',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: state.status === 'completed' ? '#48BB78' : '#E2E8F0',
          transition: 'background-color 0.2s ease'
        }}>
          <Icon 
            name={state.status === 'completed' ? 'check' : 'circle outline'} 
            style={{ 
              color: state.status === 'completed' ? '#FFFFFF' : '#A0AEC0',
              fontSize: '12px'
            }} 
          />
        </div> */}

        <div style={{ marginBottom: '24px' }}>
          <div style={{
            width: '56px',
            height: '56px',
            borderRadius: '12px',
            background: '#F7FAFC',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '16px'
          }}>
            <Icon 
              name={icon} 
              size="large"
              style={{ color: '#6435C9' }} 
            />
          </div>
          <Header as="h3" style={{ 
            margin: '0 0 8px 0',
            color: '#2D3748',
            fontSize: '18px',
            fontWeight: 600
          }}>
            {title}
          </Header>
          <p style={{ 
            color: '#718096',
            margin: '0',
            fontSize: '14px',
            lineHeight: '1.5'
          }}>
            {description}
          </p>
        </div>

        {state.file ? (
          <div style={{ marginTop: 'auto' }}>
            <div style={{
              padding: '12px',
              background: '#F7FAFC',
              borderRadius: '8px',
              marginBottom: '16px'
            }}>
              <div style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}>
                <Icon name="file pdf outline" style={{ color: '#718096' }} />
                <div style={{ flex: 1 }}>
                  <p style={{ 
                    margin: '0 0 4px 0',
                    color: '#2D3748',
                    fontSize: '14px',
                    fontWeight: 500
                  }}>
                    {state.file.name}
                  </p>
                  <p style={{ 
                    margin: 0,
                    color: '#718096',
                    fontSize: '12px'
                  }}>
                    {(state.file.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
                {state.status === 'completed' && (
                  <Icon name="check circle" style={{ color: '#48BB78' }} />
                )}
              </div>
              {state.status === 'uploading' && (
                <Progress 
                  percent={state.progress} 
                  size='tiny'
                  color='violet'
                  style={{ 
                    margin: '12px 0 0 0',
                    background: '#EDF2F7'
                  }}
                />
              )}
            </div>
            {/* <Button
              fluid
              color='violet'
              loading={state.status === 'uploading'}
              disabled={state.status === 'uploading' || state.status === 'completed'}
              // onClick={() => handleUpload(type)}
              style={{
                borderRadius: '8px',
                height: '40px',
                fontWeight: 500
              }}
            >
              {state.status === 'completed' 
                ? 'Upload Complete' 
                : state.status === 'uploading'
                ? 'Uploading...'
                : 'Start Upload'}
            </Button> */}
          </div>
        ) : (
          <div style={{ marginTop: 'auto' }}>
            <input
              type="file"
              accept={ACCEPTED_FILE_TYPES}
              style={{ display: 'none' }}
              id={`file-upload-${type}`}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileDrop(file, type);
              }}
            />
            <label 
              htmlFor={`file-upload-${type}`} 
              style={{ width: '100%' }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <div style={{
                ...dropZoneStyle,
                borderColor: isHovered ? '#6435C9' : dropZoneStyle.border,
                backgroundColor: isHovered ? '#F7FAFC' : dropZoneStyle.backgroundColor
              }}>
                <Icon 
                  name="cloud upload"
                  size="large"
                  style={{ 
                    color: dragState.isDragging ? '#48BB78' : '#6435C9',
                    marginBottom: '12px'
                  }} 
                />
                <p style={{
                  margin: '0 0 4px 0',
                  color: '#2D3748',
                  fontSize: '14px',
                  fontWeight: 500
                }}>
                  {dragState.isDragging 
                    ? 'Drop File Here'
                    : <>Drop your file here or <span style={{ color: '#6435C9' }}>browse</span></>
                  }
                </p>
                <p style={{
                  margin: 0,
                  color: '#718096',
                  fontSize: '12px'
                }}>
                  PDF up to 20MB
                </p>
              </div>
            </label>
          </div>
        )}
      </div>
    );
  };

  if (!project) return <LoadingBlock message="Loading Project..." />;

  const canProcessDocuments = proposalUpload.file && billsUpload.file &&
    proposalUpload.status !== 'uploading' && billsUpload.status !== 'uploading';

  const getStepStatus = () => {
    if (!proposalUpload.file || !billsUpload.file) return 'Please make sure both documents are uploaded';
    if (proposalUpload.status === 'uploading' || billsUpload.status === 'uploading') return 'Uploading...';
    if (proposalUpload.status === 'completed' && billsUpload.status === 'completed') return 'Ready to Process';
    else if(canProcessDocuments) return 'Please go ahead and process the documents';
    return 'Upload in Progress';
  };

  return (
    <Segment style={{ 
      padding: "40px",
      background: '#FFFFFF',
      border: 'none',
      boxShadow: 'none'
    }}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div style={{ 
              marginBottom: '40px',
              borderBottom: '1px solid #E2E8F0',
              paddingBottom: '24px'
            }}>
              <Header as="h1" style={{ 
                color: '#1A202C',
                fontSize: '24px',
                fontWeight: 600,
                marginBottom: '8px'
              }}>
                Upload Site Documents
              </Header>
              <p style={{ 
                color: '#718096',
                fontSize: '16px',
                margin: 0
              }}>
                Please upload both, your solar proposal and energy bills to proceed with the setup
              </p>
            </div>

            <div style={{ 
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '24px',
              marginBottom: '32px'
            }}>
              <UploadCard
                type="proposal"
                state={proposalUpload}
                onFileSelect={(file) => handleFileDrop(file, 'proposal')}
              />
              <UploadCard
                type="bills"
                state={billsUpload}
                onFileSelect={(file) => handleFileDrop(file, 'bills')}
              />
            </div>

            <Segment style={{
              background: '#F8FAFC',
              borderRadius: '12px',
              border: '1px solid #E2E8F0',
              padding: '24px',
              marginTop: '24px'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <div>
                  <Header as="h4" style={{ 
                    margin: '0 0 4px 0',
                    color: '#2D3748',
                    fontSize: '16px',
                    fontWeight: 600
                  }}>
                    Document Status
                  </Header>
                  <p style={{ 
                    margin: '0',
                    color: '#718096',
                    fontSize: '14px'
                  }}>
                    {getStepStatus()}
                  </p>
                </div>
                <Button
                  primary
                  disabled={!canProcessDocuments}
                  onClick={() => {
                    if (proposalUpload.file && billsUpload.file) {
                      handleUpload('proposal');
                      handleUpload('bills');
                    }
                  }}
                  style={{
                    background: '#6435C9',
                    color: '#FFFFFF',
                    opacity: canProcessDocuments ? 1 : 0.6,
                    borderRadius: '8px',
                    padding: '12px 24px',
                    height: '40px',
                    fontWeight: 500
                  }}
                >
                  Process Documents
                </Button>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}