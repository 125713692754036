import React, { useState, KeyboardEvent } from 'react';
import { Grid, Segment, Input, Button, Message, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { LineItemsStore } from '../../../platform/ps-models/lineitems-store/LineItemsStore';
import { CANONICAL_NAME_FIELD } from '../../../platform/ps-models/lineitems-store';
import { executeAIQuery, clearChatHistory } from './aiChatbot.client';
import { useCollection, useCompanyId } from "../../core";
import { AIVisualization } from './AIVisualization';
import { buildTimedCalculatedLineItem, buildTimeDef } from '../../../platform/ps-models/line-items';
import {reverse} from "ramda";

const ChatContainer = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const InputContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
`;

const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
`;

const MessageContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const DeleteButton = styled(Button)`
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem !important;
  min-width: 2rem !important;
  height: 2rem !important;
  opacity: 0;
  transition: opacity 0.2s;
  
  ${MessageContainer}:hover & {
    opacity: 1;
  }
`;

interface ChatMessage {
  id: string;
  text: string;
  isUser: boolean;
  timestamp: Date;
  store?: LineItemsStore;
  visualization?: {
    type: 'table' | 'line' | 'bar' | 'metrics';
    aggregations?: Record<string, string>;
  };
  pairId: string; // ID to group related messages
}

interface AIPageProps {
  collection: string;
  companyId: string;
}

export const AIPage = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const companyId = useCompanyId()
  const collection = useCollection()

  const handleDeleteMessage = (pairId: string) => {
    setMessages(prev => prev.filter(message => message.pairId !== pairId));
  };

  const handleClearChat = () => {
    setMessages([]);
    clearChatHistory();
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() || loading) return;

    const pairId = Date.now().toString();

    // Add user message
    const userMessage: ChatMessage = {
      id: `${pairId}-user`,
      text: inputValue,
      isUser: true,
      timestamp: new Date(),
      pairId
    };

    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setLoading(true);

    try {
      const { store, visualization } = await executeAIQuery(inputValue, collection, companyId);

      store.getDataSet().addTimedGroupingLineItemsByField(CANONICAL_NAME_FIELD)

      // Add AI response with store data
      const aiMessage: ChatMessage = {
        id: `${pairId}-ai`,
        text: "Here's the data you requested:",
        isUser: false,
        timestamp: new Date(),
        store,
        visualization,
        pairId
      };
      setMessages(prev => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error executing AI query:', error);
      const errorMessage: ChatMessage = {
        id: `${pairId}-error`,
        text: error instanceof Error ? error.message : 'An error occurred while processing your request',
        isUser: false,
        timestamp: new Date(),
        pairId
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      <Grid.Column width={5}>
        <ChatContainer>
          <InputContainer>
            <InputWrapper>
              <Input
                fluid
                placeholder="Type your query..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && handleSendMessage()}
                disabled={loading}
              />
            </InputWrapper>
            <ButtonGroup>
              <Button primary onClick={handleSendMessage} loading={loading}>
                <Icon name="send" />
              </Button>
              <Button color="red" onClick={handleClearChat}>
                <Icon name="trash" />
              </Button>
            </ButtonGroup>
          </InputContainer>
          <MessageList>
            {reverse(messages).filter(message => message.isUser || !message.store).map((message) => (
              <MessageContainer key={message.id}>
                <Message
                  color={message.isUser ? 'blue' : 'red'}
                  style={{ marginBottom: 0 }}
                >
                  <strong>
                    {message.isUser ? 'You' : 'Error'}
                  </strong>
                  <p>{message.text}</p>
                </Message>
                <DeleteButton
                  icon
                  size="mini"
                  color="red"
                  onClick={() => handleDeleteMessage(message.pairId)}
                >
                  <Icon name="delete" />
                </DeleteButton>
              </MessageContainer>
            ))}
          </MessageList>
        </ChatContainer>
      </Grid.Column>
      <Grid.Column width={11}>
        <Segment>
          <h2>Data Visualization</h2>
          {reverse(messages).map((message) => (
            message.store && message.visualization && (
              <div key={message.id} style={{ marginBottom: '2rem' }}>
                <h3>{messages.find(m => m.pairId === message.pairId && m.isUser)?.text}</h3>
                <AIVisualization
                  store={message.store}
                  title={message.text}
                  type={message.visualization.type}
                  aggregations={message.visualization.aggregations}
                />
              </div>
            )
          ))}
          {!messages.some(m => m.store) && (
            <p>Query data will appear here.</p>
          )}
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default AIPage; 
