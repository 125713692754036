import React, {memo, useState} from 'react';
import { LineItemsStore } from '../../../platform/ps-models/lineitems-store/LineItemsStore';
import { LineItemsTableWithFormulas } from '../../../platform/lineitems-store/LineItemsTableView';
import { StoreQuery } from '../../../platform/ps-models/lineitems-store';
import { TimeSeriesChartV2 } from '../../lineitems-store/TimeSeriesChartV2';
import {Checkbox} from "semantic-ui-react";

export interface AIVisualizationProps {
  store: LineItemsStore;
  title: string;
  type: 'table' | 'line' | 'bar' | 'metrics';
  aggregations?: Record<string, string>;
}

export const AIVisualization: React.FC<AIVisualizationProps> = memo(({ store, title, type, aggregations }) => {


  let [totalsOnly, setTotalsOnly] = useState(true);

  if (type === 'line' || type === 'bar') {

    let results;

    if(totalsOnly) {
      results = store.query(StoreQuery.withField('store_groupingName'));
    } else {
      results = store.query(StoreQuery.all());
    }

    return (<div>
        <Checkbox
          label='Show totals only'
          checked={totalsOnly}
          onChange={() => setTotalsOnly(!totalsOnly)}
        />
      <TimeSeriesChartV2
        title={""}
        options={{
          type,
          granularity: store.timeIndex.getUnit(),
          plotAtLastDatesOfPeriod: true
        }}
        result={results}
      /></div>
    );
  }

  const results = store.query(StoreQuery.all());
  // Default to table view
  return (
    <LineItemsTableWithFormulas
      withGroups={true}
      store={store}
      queryResult={results}
    />
  );
}); 